import React from 'react'

import styles from './ContactsModal.module.scss'

import fbIcon from './facebook-f.svg'
import cross from './cross.svg'

function stopProp(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
}

function ContactsModal({onClick, style}) {
  return (
    <div className={styles.contactsModalWrapper} onClick={onClick} style={style}>
      <div className={styles.crossIcon}>
            <img src={cross} alt="cross-close"/>
        </div>
        <div className={styles.contactsModalContent} onClick={stopProp}>
            <ul className={styles.contactsWrapper}>
              <li><a className={styles.contactsEl} href="tel:+380675052365">+38 067 505 23 65</a></li>
              <li><a className={styles.contactsEl} target="_blank" href="https://www.facebook.com/gala.vilko">Facebook</a></li>
              <li><a className={styles.contactsEl} target="_blank" href="https://upu.com.ua/uk/registry/member/komarenko-galina-nikolaevna/">Український психоаналітичний союз</a></li>
            </ul>
        </div>
    </div>
  )
}

export default ContactsModal