import React from 'react'
import princImg from './media/princimg.webp'

import styles from './Principles.module.scss'

function Principles() {
  return (
    <div className={styles.principlesWrapper}>
        <div className={styles.princImg}>
            <img src={princImg} alt="0" />
        </div>
        <div className={styles.princCont}>
            <p className={styles.contHeader}>
                Принципи та гарантії:
            </p>
            <div className={styles.contBody}>
                <p className={styles.contInst}>
                    <b>Анонімність та конфіденційність:</b> все, що відбувається на сесії - залишається на сесії
                </p>
                <p className={styles.contInst}>
                    <b>Моя робота - моє покликання</b>
                </p>
                <p className={styles.contInst}>
                    <b>Повага до внутрішнього світу клієнта:</b> не оцінюю, не засуджую, не критикую, не заважаю. Натомість приймаю, підтримую, допомагаю
                </p>
                <p className={styles.contInst}>
                    <b>Поважаю та ціную себе, свій професіоналізм та свій час</b>
                </p>
                <p className={styles.contInst}>
                    <b>Маю бажання та ресурс допомогти тобі бути щасливим</b>
                </p>
            </div>
        </div>
    </div>
  )
}

export default Principles