import React, { useState } from 'react';
import AnimatedCursor from "react-animated-cursor"

import About from './components/About/About';
import MainScreen from './components/MainScreen/MainScreen';
import Reviews from './components/Reviews/Reviews';
import Methods from "./components/Methods/Methods";
import Formats from "./components/Formats/Formats";
import Footer from "./components/Footer/Footer";
import Modal from "./components/About/Modal/Modal";
import Principles from "./components/Principles/Principles";
import Conditions from './components/Conditions/Conditions';
import Degrees from './components/Degrees/Degrees';


const App = () => {
  React.useEffect(() => {
    // console.log('Page loaded')
  }, [])

  const [modal, setModal] = useState(false);
  function onModal() {
    document.body.style.overflow = modal ? 'unset' : 'hidden'
    return setModal(!modal)
  }

  return (
    <div className="App">
       <AnimatedCursor
      innerSize={40}
      outerSize={30}
      outerStyle={{
        mixBlendMode: 'exclusion',
      }}
      innerStyle={{
        mixBlendMode: 'exclusion',
      }}
      color='255, 255, 255'
      outerAlpha={0.2}
      innerScale={0.7}
      outerScale={2}
      trailingSpeed={16}
      clickables={[
        'a',
        'li',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link',
        'object[type="image/svg+xml"]',
        'object',
        '.bulb',
        'label'
      ]
      }
    />
    <Modal onClick={onModal} style={{opacity: modal ? '1' : '0', pointerEvents: modal ? 'all' : 'none'}}/>
    <MainScreen onModal={onModal}/>
    <About onModal={onModal}/>
    <Methods />
    <Formats />
    <Principles />
    <Conditions />
    <Degrees />
    <Reviews />
    <Footer onModal={onModal}/>
    </div>
  );
}

export default App;
