import React, { useState } from 'react'

import styles from './Reviews.module.scss'
import FirstSlideStyles from './SlidesStyles/FirstSlides.module.css'
import SecSlideStyles from './SlidesStyles/SecondSlides.module.css'

import Carousel from './Carousel/Carousel'

function Reviews() {
  const [card, setCard] = useState(0)
  const onCardChange = (event) => {
    setCard(event.currentIndex)
  }

  return (
    <>
      <div className={styles.revHeader}>
        <h2>Ефективність роботи зі мною досвідчена клієнтами</h2>
      </div>
      <div className={styles.reviewsWrapper}>
        <Carousel 
          card={card} 
          setCard={setCard} 
          onCardChange={onCardChange} 
          slideStyles={card <= 3 ? FirstSlideStyles : SecSlideStyles}/>
      </div>
    </>
  )
}

export default Reviews