import React from 'react'

import styles from './Degrees.module.scss'

function Degrees() {
  return (
    <div className={styles.degreesWrapper}>
        <div className={styles.degreesHead}>
            <h2>Моя освіта:</h2>
        </div>
        <div className={styles.degreesGrid}>
            <div className={styles.gridEl}><p>
                Диплом Міжнародний інститут глибинної психології та психоаналізу, 2002 – 2005 рр. практичний психолог, психоаналітик
            </p></div>
            <div className={styles.gridEl}><p>
                Сертифікат про закінчення курсу "Психодинамічно-орієнтована психокорекція" в ОНУ ім. І.Г. Мєчнікова, Одеса, Україна. Травень,2009
            </p></div>
            <div className={styles.gridEl}><p>
                Сертифікат з курсу "Сексологія" до Національного Педагогічного Університету ім. М.П. Драгованова. Київ, Україна. лютий 2019    
            </p></div>
            <div className={styles.gridEl}><p>
                Сертифікат про закінчення курсу "Сексологія та сексопатологія" в University of classical and modern psychology, psychotherapy and coaching. Київ, Україна. Лютий 2018    
            </p></div>
            <div className={styles.gridEl}><p>
                Сертифікат про закінчення курсу підвищення кваліфікації "Основи теорії та практики сихоаналітичної роботи з парами" до Української психоаналітичної спілки. Київ, Україна, жовтень 2018
            </p></div>
            <div className={styles.gridEl}><p>
                Сертифікат про закінчення курсу "Психіатрія для психологів" у ВООППГ (всеукраїнське товариство психологів, які практикують гештальт підхід), Київ, Україна. грудень 2018
            </p></div>
            <div className={styles.gridEl}><p>
                Практичне вивчення психоаналізу (дидактичний аналіз) – понад 300 годин. (МІГП)    
            </p></div>
            <div className={styles.gridEl}><p>
                Магістратура ПВНЗ «Європейський університет» з практичної психології - з 2021р.
            </p></div>
            <div className={styles.gridEl}><p>
                Сертифікат про закінчення курсу «Військова психологія для психолога» в ГО «Українська асоціація фахівців по подоланню наслідків психотравмуючих подій»    
            </p></div>
        </div>
    </div>
  )
}

export default Degrees