import React from 'react'

import styles from './Formats.module.scss'

function Formats() {
  return (
    <div className={styles.formatsWrapper}>
        <div className={styles.formats}>
          <div className={styles.formatsHead}>
            <h2>Формати, в яких я працюю:</h2>
          </div>
          <ul className={styles.formatsList}>
            <li className={styles.format}>Психологічне консультування</li>
            <li className={styles.format}>Індивідуальна терапія довгострокова</li>
            <li className={styles.format}>Консультування пар</li>
            <li className={styles.format}>Індивідуальна терапія короткострокова</li>
          </ul>
        </div>
    </div>
  )
}

export default Formats