import React from 'react'

import styles from './Methods.module.scss'

function Methods() {
  return (
    <div className={styles.methodsWrapper} id="methods">
        <div className={styles.methodsContent}>
            <h2>Методи, які я використовую в роботі</h2>
            <div className={styles.methods}>
              <div className={styles.methodEl}>Психоаналіз</div>
              <div className={styles.methodEl}>Когнітивно - поведінкова терапія</div>
              <div className={styles.methodEl}>Діалектично - поведінкова терапія</div>
              <div className={styles.methodEl}>Схема - терапія</div>
              <div className={styles.methodEl}>Емоційно-фокусувана терапія індивідуальна та пар</div>
            </div>
        </div>
    </div>
  )
}

export default Methods