import React, { useState } from 'react'
import ChecklistItem from './CheckListItem/CheckListItem'

import styles from './About.module.scss'
import illCart from './illCart.svg'

const illnesses = ["Втрата опори, орієнтирів, сенсів", "Страх, тривога, панічні атаки", 
                   "Розгубленість, безпорадність", "Караєш себе, знецінюєш і зневажаєш", 
                   "Не знаєш хто ти і чого прагнути", "Зловживаєш алкоголем або ще будь чим, аби зменшити біль",
                   "Не віриш в себе і свої сили", "Розпач, зневіра, розчарування, відчай", "Гнів, агресія, дратівливість", 
                   "Почуття провини, сорому", "Почуваєшся самотнім та нікому не потрібним", 
                   "Втома, виснаження, вигорання", "Проблеми у стосунках з людьми, конфлікти, непорозуміння", 
                   "Не бачиш майбутнього"]

function AboutYou(props) {
const [displayA, setDisplayA] = useState(true);
const [displayB, setDisplayB] = useState(false);
const [displayC, setDisplayC] = useState(false);

const showA = () => {
    setDisplayA(true);
    setDisplayB(false);
    setDisplayC(false);
}
const showB = () => {
    setDisplayA(false);
    setDisplayB(true);
    setDisplayC(false);
};
const showC = () => {
    setDisplayA(false);
    setDisplayB(false);
    setDisplayC(true);
};

const [activeItemsCount, setActiveItemsCount] = useState(0);
const illList = illnesses.map((ill, index) => {
    return (
        <ChecklistItem
            key={index}
            id={index % 2 == 0 ? 'leftCheck' : 'rightCheck'}
            action={ill.action}
            setActiveItemsCount={setActiveItemsCount}
            illName={ill}
      />
    )
})

const aboutOff = {
    backgroundColor: '#000',
    color: '#fff',
    fontWeight: '400'
}
const aboutOn = {
    backgroundColor: '#fff',
    color: '#000',
    fontWeight: '700'
}

return (
    <div className={styles.aboutWrapper}>
        <div className={styles.togglerWrapper}>
            <button onClick={showA} className={styles.toggleButton} style={displayA ? aboutOn : aboutOff}>Про тебе</button>
            <button onClick={showB} className={styles.toggleButton} style={displayB ? aboutOn : aboutOff}>Про мене</button>
            <button onClick={showC} className={styles.toggleButton} style={displayC ? aboutOn : aboutOff}>Про нас</button>
        </div>
        {displayA && 
        <>
            <div className={styles.listWrapper}>
                {illList}
            </div>
            <button className={styles.talkBtn} onClick={props.onModal}>
                    <div className={styles.talkBtnIn}>
                        <img src={illCart} className={styles.cartImg} alt=""/>
                        <p className={styles.activeItemsCount}>{activeItemsCount}</p> 
                        <p className={styles.talk}>Поговоримо?</p>
                    </div>
            </button>
        </>
        }
        {displayB && 
            <div className={styles.aboutMeWrapper}>
                <div className={styles.upuWrapper}>
                    <h1>UPU</h1>
                    <p>Дипломований практичний психолог, психоаналітик, член Українського Психоаналітичного Союзу (UPU)</p>
                </div>
                <div className={styles.yWrapper}>
                    <h1>{'>'} 15 років</h1>
                    <p>постійного навчання і вдосконалення</p>
                </div>
                <div className={styles.hWrapper}>
                    <h1>{'>'} 300 годин</h1>
                    <p>власної терапії</p>
                </div>
                <div className={styles.cWrapper}>
                    <h1>{'>'} 300 клієнтів</h1>
                    <p>що змінили своє життя і стали щасливіші</p>
                </div>
                <div className={styles.ycWrapper}>
                    <h1>{'>'} 10 років</h1>
                    <p>успішної роботи з клієнтами</p>
                </div>
                <div className={styles.sWrapper}>
                    <h1>Сеанси</h1>
                    <p>українською та російською мовами</p>
                </div>
                <div className={styles.bWrapper}>
                    <button className={styles.moreButton}>Більше про мене</button>
                </div>
            </div>
        }
        {displayC && 
            <div className={styles.usWrapper}>
                <p className={styles.usItemOne}>
                    Моя щира цікавість до твого внутрішнього світу, бажання допомогти та високий професіоналізм створять безпечний простір прийняття, підтримки та зростання
                </p>
                <p className={styles.usItemTwo}>
                    Ти будеш досліджувати свій внутрішній світ: спосіб мислення, почуття, стратегії адаптації до зовнішнього світу, сценарії у відносинах з людьми, паттерни поведінки
                </p>
                <p className={styles.usItemThree}>
                    Разом ми зробимо ревізію твого «даху», проаналізуємо те, що там є зараз, проживемо по новому твій  травматичний досвід та створимо новий сценарій-стратегію твого життя: з новим баченням, сприйняттям, свідомими рішеннями та виборами
                </p>
                <p className={styles.usItemFour}>
                    Ти станеш вільним, свідомим, відповідальним, навчишся більшому впливу і контролю над своїм життям, а значить життя твоє буде більш наповненим і щасливішим
                </p>
            </div>
        }
    </div>
)
}

export default AboutYou