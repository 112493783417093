import React, { useState, useEffect } from "react";
import styles from './CheckListItem.module.scss'
import plusIcon from './plusAbout.svg'

const ChecklistItem = ({ setActiveItemsCount, illName, id }) => {
const [isActive, setIsActive] = useState(false);

const changeHandler = () => {
setIsActive(!isActive);
};

useEffect(() => {
    if (!isActive) {
        setActiveItemsCount((prevCount) => {
            if (prevCount !== 0) {
                return prevCount - 1;
            }
            return prevCount;
        });
    }

if (isActive) {
    setActiveItemsCount((prevCount) => prevCount + 1);
}
}, [isActive, setActiveItemsCount]);

  return <div className={styles.check} id={id}>
            <label>
                <input
                    checked={isActive} 
                    onChange={changeHandler}
                    type="checkbox"
                />
                <span>{illName}<img src={plusIcon} type="image/svg+xml"/></span>
            </label>
        </div>
};

export default ChecklistItem;