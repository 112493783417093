import React, { StyleSheet, useState } from 'react'

import { StackedCarousel } from 'react-stacked-carousel'

import './carousel.css';
import styles from './Carousel.module.scss'

import revOne from './media/rev1.webp'
import revTwo from './media/rev2.webp'
import revThree from './media/rev3.webp'
import revFour from './media/rev4.webp'
import revFive from './media/rev5.webp'
import revSix from './media/rev6.webp'
import revSev from './media/rev7.webp'
import revEight from './media/rev8.webp'
import revNine from './media/rev9.webp'
import revTen from './media/rev10.webp'



import arrUp from './media/arrUp.svg'
import arrDown from './media/arrDown.svg'

const Carousel = (props) => {
  // let arrVis = {
  //   pointerEvents: 'auto',
  //   visibility: 'visible'
  // }
  // let arrHid = {
  //   pointerEvents: 'none',
  //   visibility: 'hidden',
  //   zIndex: '-100000'
  // }
  return (
    <div className="main"> 
        <StackedCarousel
            autoRotate = {false}
            onCardChange = {props.onCardChange}
            leftButton = {
              <img src={arrDown} 
                alt='<'
            />}
            rightButton = {
              <img 
                src={arrUp} 
                alt='>'
              />}
        >
          <img key={'img1'} className={props.slideStyles.revOne} src={revOne} alt="0" />
          <img key={'img2'} className={props.slideStyles.revTwo} src={revTwo} alt="1" />
          <img key={'img3'} className={props.slideStyles.revThree} src={revThree} alt="2" />
          <img key={'img4'} className={props.slideStyles.revFour} src={revFour} alt="3" />
          <img key={'img5'} className={props.slideStyles.revFive} src={revFive} alt="4" />
          <img key={'img6'} className={props.slideStyles.revSix} src={revSix} alt="5" />
          <img key={'img7'} className={props.slideStyles.revSev} src={revSev} alt="6" />
          <img key={'img8'} className={props.slideStyles.revEight} src={revEight} alt="7" />
          <img key={'img9'} className={props.slideStyles.revNine} src={revNine} alt="8" />
          <img key={'img10'} className={props.slideStyles.revTen} src={revTen} alt="9" />
        </StackedCarousel>
      </div>
  )
}
export default Carousel