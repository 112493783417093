import React, { useState } from "react";
import styles from './Modal.module.scss'

import cross from './cross.svg'
import checkmark from './checkmark.svg'
    
function stopProp(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
}

function Modal ({ onClick, style, arrAbout }) {
const arrOfIll = ["😔 Депресія", "😰 Тривога", 
                  "🥂 Проблеми з залежностями", 
                  "😵‍💫 Панічні атаки", "😶 Розчарування",
                  "😖 Відчуття провини"]

const [name, setName] = useState("")
const [phone, setPhone] = useState("")
const [textarea, setTextarea] = useState("")

// Handling checkboxes
const [checkedState, setCheckedState] = useState(new Array (arrOfIll.length).fill(false))

let illSubmit = []
const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    )
    setCheckedState(updatedCheckedState)
}

for (let i = 0; i < checkedState.length; i++) {
    if (checkedState[i]) {
        illSubmit.push(arrOfIll[i])    
    }
}

// Handle form submit
const [showPopup, setShowPopup] = useState(false);
let handleSubmit = async (e) => {
	e.preventDefault();
    try {
        const response = await fetch("https://halynakomarenko.com.ua/form/form.php", {
        method: "POST",
        header: {
            'Content-Type': 'multipart/form-data'
        },
        body: JSON.stringify({
            name: name,
            phone: phone,
            textarea: textarea,
            ills: illSubmit
        }),
        })
        if (response.ok) {
            // console.log("Form submitted successfully!");
            // alert("Повідомлення успішно відправлено!")
            setShowPopup(true)
            setTimeout(() => {
                setShowPopup(false);
              }, 4000); // hide the popup after 3 seconds

          } else {
            // console.log("Form submission failed");
            alert("Щось пішло не так. Спробуйте ще раз")
          }
    } catch (err) {
        // console.log(err)
        alert("Щось пішло не так. Спробуйте ще раз")
    } 
};

return (
    <div className={styles.modalWrapper} onClick={onClick} style={style}>
        <div className={styles.crossIcon}>
            <img src={cross} alt="cross-close"/>
        </div>
            <div className={`${styles.popUpWrapper} ${showPopup ? styles.show : ""}`}>
                <div className={styles.popUp}>
                    <div className={styles.checkMark}>
                        <img src={checkmark} alt="checkmark"/>
                    </div>
                    <p>Дані успішно відправлені!</p>
                </div>
            </div>    
        {/* <div className={styles.popupConfirm}>
            <p>Message was sent.</p>
        </div> */}
        <div className={styles.formWrapper} onClick={stopProp}>
            <div className={styles.headWrapper}>
                <h1>Що вас турбує?</h1>
            </div>
            <form onSubmit={handleSubmit.bind()}>
                <div className={styles.symWrapper}>
                {arrOfIll.map((ill, index) => {
     return (
        <div className={styles.symEl}>
            <label htmlFor={`custom-checkbox-${index}`}> 
                <input 
                    type="checkbox"
                    id={`custom-checkbox-${index}`}
                    name={ill}
                    checked={checkedState[index]}
                    onChange={() => handleOnChange(index)}
                    /> 
                <span>{ill}</span>
            </label>
        </div>
        )
        })}
            </div>
                <div className={styles.textWrapper}>
                    <textarea 
                        type="text"
                        id="textarea" 
                        name='textarea' 
                        cols="10" 
                        rows="6" 
                        placeholder="Розкажіть про ваш запит більше..."
                        onChange={(e) => setTextarea(e.target.value)}
                        ></textarea>
                </div>
                <div className={styles.nameWrapper}>
                    <label htmlFor='name'>
                        <input 
                            required="required"
                            type="text"
                            id='name' 
                            name='name' 
                            placeholder="Ваше імʼя" 
                            value={name} 
                            onChange={(e) => setName(e.target.value)}
                            />
                    </label>
                </div>
                <div className={styles.phoneWrapper}>
                    <label htmlFor='phone'>
                        <input 
                            required="required"
                            type="tel"
                            id='phone' 
                            name='phone' 
                            placeholder="Ваш телефон або месенджер" 
                            value={phone} 
                            // pattern="[a-z]|[A-Z]|[0-9]" 
                            minLength="3"
                            maxlength="40"
                            onChange={(e) => setPhone(e.target.value)}
                            />
                    </label>
                </div>
                {/* <div className={styles.emailWrapper}>
                    <label htmlFor='email'>
                        <input 
                            required="required"
                            type="email"
                            id='email' 
                            name='email' 
                            placeholder="Ваш email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)}
                            />
                    </label>
                </div> */}
                <button className={styles.submitBtn}>Відправити</button>
            </form>
        </div>
    </div>
  )
}

export default Modal