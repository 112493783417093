import React, { useState }  from 'react'

import bulbOff from './bulb.png'
import bulbOn from './bulb_on.png'
import wire from './wire.svg'
import wireOn from './wire_on.svg'
import ellipses from './ellipses.svg'
import arrow from './arrow.svg'

import { browserName, isMobile } from 'react-device-detect'

import styles from './Bulb.module.scss'

let boxShadowSet = ''
if (!(browserName === "Safari") && isMobile) {
    boxShadowSet = '24px 18px 190px 170px #EDCEA0, 26px 18px 300px 150px #FFF4CF, 24px 0 450px 500px rgb(255, 220, 130, 0.6)'
} else if (browserName === "Chrome" && !isMobile) {
    boxShadowSet = '0px 0px 80px 60px rgb(255, 244, 207, 0.6), 0 0 500px 140px rgb(255, 220, 130, 0.5)'
}
else {
    boxShadowSet = '22px 0px 190px 150px #EDCEA0, 18px 0 200px 450px rgb(255, 244, 207, 0.5), 24px 0 300px 700px rgb(255, 220, 130, 0.6)'
} 
// document.body.style.overflow = 'hidden'
let bulbToggle = <iframe src={bulbOff} type="image/svg+xml" className={styles.bulb}></iframe>;
const Bulb = ({isOn, setisOn, toggleIsOn}) => {
    const toggleEllipses = {
        animation: isOn ? 'ellipsesOff 0.5s' : 'pulsating 3s infinite',
        animationFillMode: isOn ? 'forwards' : ''
    }
    const toggleLights = {
        boxShadow: isOn ? boxShadowSet : '',
        MozBoxShadow: isOn ? boxShadowSet : '',
        WebkitBoxShadow: isOn ? boxShadowSet : '',
        animation: isOn ? 'pulsatingLight 7s infinite' : '',
        scale: (isOn && (browserName === "Chrome" && !isMobile)) ? "4" : "1"
    }
    if (isOn) {
        bulbToggle = <iframe src={bulbOn} type="image/svg+xml" className={styles.bulb}></iframe>
    } else {
        bulbToggle = <iframe src={bulbOff} type="image/svg+xml" className={styles.bulb}></iframe>
    }
  return (
    <>
        <div className={styles.bulbWrapper} onClick={toggleIsOn}>
            <div className={styles.arrowWrapper} style={{opacity: isOn ? "0" : "1"}}>
                <p className={styles.arrowText} translate="no">Тиць!</p>
                <img src={arrow} type="image/svg+xml" alt="pointer-arrow" className={styles.bulbArrow} />
            </div>
            <img src={wire} type="image/svg+xml" className={styles.wire}/>
                {/* {bulbToggle} */}
                <img src={isOn ? bulbOn : bulbOff} className={styles.bulb}></img>
            <span className={styles.lights} style={toggleLights}></span>
            <object data={ellipses} type="image/svg+xml" className={styles.ellipses} style={toggleEllipses}></object>
        </div>
     </>
  )
}

export default Bulb