import React, { useState } from 'react'

import Modal from '../About/Modal/Modal';

import styles from './Footer.module.scss'

function Footer(props) {
  return (
    <div className={styles.footerWrapper} id="footer">
        <div className={styles.footerContent}>
            <p className={styles.footerHeader}>
                Почни змінювати своє життя вже сьогодні.
            </p>
            <button className={styles.footerBtn} onClick={props.onModal}>Зробити крок</button>
        </div>
    </div>
  )
}

export default Footer