import React, { useState } from 'react'
import { MobileView, BrowserView } from 'react-device-detect'
import { slide as Menu } from 'react-burger-menu'

import mainImage from './main_image.webp'
import crossIcon from './crossIcon.svg'


import styles from './MainScreen.module.scss'
import './burger/burger.css'

import Bulb from '../Bulb/Bulb'
import ContactsModal from './ContactsModal/ContactsModal'

const MainScreen = (props) => {
  const [isOn, setisOn] = useState(false)
  const toggleIsOn = () => {
    setisOn(current => !current)
}
  const [contactsModal, setContactsModal] = useState(false);

  // Burger handling
  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  function onContactsModal() {
    document.body.style.overflow = contactsModal ? 'unset' : 'hidden';
    return setContactsModal(!contactsModal)
  }

  const toggleImageStyles = {
    opacity: isOn ? '1' : '0.2'
  }
  const menuStyles = {
    opacity: isOn ? '1' : '0'
  }
  return (
    <div className={styles.mainScreenWrapper}>
      <MobileView>
        <Menu 
          customCrossIcon={ <img src={crossIcon} alt='cross icon' />}
          bubble
          width={ '100%' } 
          noOverlay 
          right 
          scaleDown 
          onStateChange={handleStateChange}
          isOpen={menuOpen} >
          <a href="#methods" onClick={closeMenu}>Про мене</a>
          <a onClick={onContactsModal}>Контакти</a>
          <a href="#footer" className="formBtn" onClick={closeMenu}>Записатись на сеанс</a>
          <ContactsModal onClick={onContactsModal} style={{opacity: contactsModal ? '1' : '0', pointerEvents: contactsModal ? 'all' : 'none'}}/>
        </Menu>
      </MobileView>
      <div className={styles.headerWrapper} style={menuStyles}>
      <BrowserView>
        <ul className={styles.menu}>
          <li><a href="#methods">Про мене</a></li>
          <li onClick={onContactsModal}>Контакти</li>
          <li className={styles['nav-button']} onClick={props.onModal}>Записатись на сеанс</li>
          <ContactsModal onClick={onContactsModal} style={{opacity: contactsModal ? '1' : '0', pointerEvents: contactsModal ? 'all' : 'none'}}/>
        </ul>
        </BrowserView>
      </div>
      <div className={styles.mainWrapper}>
          <div className='bulb'>
            <Bulb isOn={isOn} setisOn={setisOn} toggleIsOn={toggleIsOn}/>
          </div>
          <div className={styles.mainLeft}>
            <h1 className={styles.headTitle}>УВІМКНИ СВІТЛО І ЗРОБИ КРОК</h1>
            <p>Твій психолог –– Галина Комаренко</p>
          </div>
          <div className={styles.mainRight}>
            <img className={styles.mainImage} src={mainImage} alt='Галина Комаренко' style={toggleImageStyles}/>
          </div>
      </div>
    </div>
  )
}

export default MainScreen