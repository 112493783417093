import React from 'react'

import styles from './Conditions.module.scss'

function Conditions() {
  return (
    <div className={styles.condWrapper}>
        <div className={styles.conds}>
          <div className={styles.condHead}>
            <h2>Умови:</h2>
          </div>
          <ul className={styles.condList}>
            <li className={styles.cond}>
                Зустрічі наживо в <b>центрі Києва або онлайн.</b>
            </li>
            <li className={styles.cond}>
                Тривалість сесії - <b>1 година (60 хв)</b> для одного клієнта, <b>2 години (120 хв)</b> - для пар.
            </li>
            <li className={styles.cond}>
                Вартість сесії - <b>1200 грн (30$ або €)/година</b> для одного клієнта, <b>1000 грн (25$ або€)/година</b> роботи для пар.
            </li>
            <li className={styles.cond}>
            <b>Пропуск сесії сплачується клієнтом</b> або зустріч переноситься на інший день на цьому ж тижні за умови, якщо у мене є можливість і клієнт попередив про перенос не пізніше ніж за добу.
            </li>
          </ul>
        </div>
    </div>
  )
}

export default Conditions